<template>
  <div>
    <div class="top">
      <div class="box">
        <ProductList></ProductList>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="title">USDT</div>
        <div class="nav">
          <div class="item" @click="sort('name')">
            <span>{{ $t('quotation').mc }}</span>
            <div class="arrow">
              <van-icon name="play" />
              <van-icon name="play" />
            </div>
          </div>
          <div class="item" @click="sort('price')">
            <span>{{ $t('quotation').zxj }}</span>
            <div class="arrow">
              <van-icon name="play" />
              <van-icon name="play" />
            </div>
          </div>
          <div class="item" @click="sort('percentage')">
            <span>{{ $t('quotation').zdb }}</span>
            <div class="arrow">
              <van-icon name="play" />
              <van-icon name="play" />
            </div>
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="item in productList"
            :key="item.Id"
            @click="$router.push({ name: 'rapidly', params: { id: item.Id } })"
          >
            <div class="left">
              <div class="name">
                <span>{{ showProductName(item.Id) }} </span>/USDT
              </div>
              <div>24H {{ $t('quotation').liang }} {{ item.Amount }}</div>
            </div>
            <div class="price">
              {{ item.Price }}
            </div>
            <div
              class="range"
              :style="{
                background:
                  item.percentage < 0
                    ? basicConfig.FallColour
                    : basicConfig.RiseColour
              }"
            >
              {{
                item.percentage > 0 ? `+${item.percentage}` : item.percentage
              }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductList from '../home/component/ProductList.vue'
import { mapGetters } from 'vuex'
import productApi from '@/api/product'

export default {
  name: 'CurrencyMobileNewIndex',
  components: {
    ProductList
  },

  data() {
    return {
      proList: [],
      productList: [],
      ProductNames: [],
      productBasic: [],
      active: 'Quotation',
      nameFlag: false,
      percentageFlag: false,
      priceFlag: false,
      totalName: this.$t('quotation').title
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
  async mounted() {
    await this.getList()
    this.Basic()
    this.signalRStart()
  },
  methods: {
    sort(type) {
      let productList = this.productList
      if (type === 'name') {
        this.nameFlag = !this.nameFlag
        productList = productList.sort((a, b) => {
          if (this.nameFlag) {
            return a.Name.charCodeAt() - b.Name.charCodeAt()
          } else {
            return b.Name.charCodeAt() - a.Name.charCodeAt()
          }
        })
      }
      if (type === 'price') {
        this.priceFlag = !this.priceFlag
        productList = productList.sort((a, b) => {
          if (this.priceFlag) {
            return a.Price - b.Price
          } else {
            return b.Price - a.Price
          }
        })
      }
      if (type === 'percentage') {
        this.percentageFlag = !this.percentageFlag
        productList = productList.sort((a, b) => {
          if (this.percentageFlag) {
            return a.percentage - b.percentage
          } else {
            return b.percentage - a.percentage
          }
        })
      }

      this.productList = productList
    },
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
      this.productBasic.forEach((item) => {
        item.difference = item.Price - item.BasicPrice
        item.difference = Number(item.difference).toFixed(2)
        item.percentage = (item.difference / item.Price) * 100
        item.percentage = Number(item.percentage).toFixed(2)
        item.Name = this.showProductName(item.Id)
        this.proList.some((iitem) => {
          if (iitem.Id === item.Id) {
            item.Amount = iitem.Amount
            return true
          }
        })
      })
      this.productList = this.productBasic
    },
    showPrice(Price) {
      const nowPrice = Number(Price)
      if (nowPrice >= 100) {
        return nowPrice.toFixed(2)
      } else if (nowPrice < 100 && nowPrice > 0.1) {
        return nowPrice.toFixed(4)
      } else {
        return nowPrice
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'quotation')
        }
      }, 100)
      connection.on('QuotationSecondPrices', function(res) {
        let productBasic = [...that.productBasic]
        res.forEach((item) => {
          productBasic.forEach((sItem) => {
            if (item.Id === sItem.Id) {
              sItem.Price = item.Price
              sItem.Amount = item.Amount
              sItem.difference = item.Price - sItem.BasicPrice
              sItem.difference = sItem.difference.toFixed(2)
              sItem.percentage = (sItem.difference / item.Price) * 100
              sItem.percentage = sItem.percentage.toFixed(2)
            }
          })
        })
        productBasic.forEach((item) => {
          that.productList.forEach((iItem) => {
            if (item.Id === iItem.Id) {
              iItem.Price = item.Price
              iItem.Amount = item.Amount
              iItem.percentage = item.percentage
            }
          })
        })
      })
      connection.on('ProductNames', function(res) {
        that.ProductNames = res
      })
    },
    async getList() {
      this.proList = await productApi.list()
    },
    showProductName(ProductId) {
      let name = ''
      this.proList.forEach((item) => {
        if (item.Id === ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  background-color: #1e1e1e;
  .box {
    width: 80%;
    margin: 0 auto;
  }
}
.main {
  background-color: #f8f8f8;
  padding: 30px 0;
  .container {
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    background: #fff;
    border-radius: 6px;
    min-height: 500px;
    margin-bottom: 20px;
    width: 60%;
    margin: 0 auto;
    min-height: 100vh;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #989898;
      position: relative;
      padding: 10px 20px;
      height: 45px;
      border-bottom: 1px solid #ebeef5;
      &::after {
        position: absolute;
        content: '';
        bottom: 12px;
        width: 20px;
        height: 3px;
        left: 28px;
        background: #e143ac;
      }
    }
    .nav {
      display: flex;
      height: 35px;
      align-items: center;
      font-size: 12px;
      color: #4a5a7a;
      background-color: #f8f8f8;
      padding: 10px;
      justify-content: space-around;
      .item {
        display: flex;
        align-items: center;
        // width: 171px;

        .arrow {
          margin-left: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .van-icon {
            font-size: 8px;
            transform: rotate(-90deg);
            position: relative;
            top: 3px;
            &:nth-of-type(2) {
              top: -3px;
              transform: rotate(90deg);
            }
          }
        }
        // &:nth-of-type(2) {
        //   width: 114px;
        // }
        // &:nth-of-type(3) {
        //   flex: 1;
        // }
      }
    }
    .list {
      color: #ffffff;
      .item {
        height: 58px;
        margin: 10px 0;
        padding: 0 10px;
        display: flex;
        font-size: 12px;
        align-items: center;
        border-bottom: 1px solid #ebeef5;
        display: flex;
        justify-content: space-around;
        text-align: center;
        .left {
          color: #9fbedc;
          width: 171px;
          flex: 1;
          .name {
            margin-bottom: 5px;
          }
          span {
            color: #606266;
            font-weight: bold;
            font-size: 14px;
          }
        }
        .price {
          font-size: 14px;
          font-weight: bold;
          color: #606266;
          width: 114px;
          flex: 1;
        }
        .range {
          flex: 1;
          height: 24.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 400;
          background: #02c289;
          border-radius: 2px;
        }
        .up {
          background: #e27046;
          color: #fff !important;
        }
      }
    }
  }
}
</style>
